<template>
  <footer>
    <div class="content">
      <div class="footer-top">
        <dl>
          <dd>
            <span>24小时服务热线</span>
            <span>400-9699-225</span>
          </dd>
          <!-- <dd>
            <span>24小时服务热线</span>
            <span>+86 1008611</span>
          </dd> -->
          <!-- <dd>
            <div>在线客服</div>
          </dd> -->
        </dl>
      </div>
      <div class="footer-menu">
        <ul class="menu-item" v-for="(item, index) in menu" :key="index">
          <li>{{item.label}}</li>
          <li v-for="(it, i) in item.child" :key="i"><a :href="it.url">{{it.label}}</a></li>
        </ul>
        <div class="footer-menu-right">
          <div class="ewm-item">
            <img src="@/assets/images/ewm.jpg" alt="">
            <div>多知公众号</div>
          </div>
          <img class="logo" src="@/assets/images/logo.png" alt="">
        </div>
      </div>
      <div class="footer-bottom">
        <img src="@/assets/images/gsgl.png" alt="">
        <span>Copyright  2020 版权所有 深圳多知能源物联有限公司</span>
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2020075822号</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data(){
    return {
      menu: [
        {
          label: "产品中心",
          child: [
            {label: "智能硬件", url: "/product/?type=intelligentHardware"},
            {label: "软件平台", url: "/product/?type=softwarePlatform"},
          ]
        },
        {
          label: "关于我们",
          child: [
            {label: "企业介绍", url: "/about/enterpriseIntroduction"},
            {label: "荣誉资源", url: "/about/honoraryQualifications"},
            {label: "品质保障", url: "/about/qualityAssurance"},
            {label: "多知视频", url: "/about/companyVideo"},
            {label: "加入多知", url: "/about/join"},
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
footer{
  background: #333333;
  color: white;
  text-align: left;
  .content{
    font-weight: 600;
    max-width: 1600px;
    .footer-top{
      height: 108px;
      border-bottom: 2px solid #a6a6a6;
      dl{
        margin: 0;
        dd{
          float: left;
          width: 250px;
          height: 108px;
          margin: 0;
          padding-left: 10px;
          span:nth-child(1){
            color: white;
            display: block;
            padding-top: 40px;
            line-height: 20px;
            font-size: 15px;
          }
          span:nth-child(2){
            color: #2da0db;
            height: 48px;
            font-size: 28px;
            color: #2da0db;
          }
        }
        & > dd:nth-child(2){
          float: right;
          div{
            text-align: center;
            width: 100px;
            line-height: 40px;
            margin-top: 55px;
            font-size: 15px;
            display: block;
            float: right;
            background-color: #2da0db;
            color: #fff;
          }
        }
      }

    }
    .footer-menu{
      padding: 0 10px;
      color: white;
      .menu-item{
        list-style: none;
        float: left;
        width: 90px;
        text-align: center;
        padding: 0;
        li{
          font-size: 14px;
          color: #fff;
          line-height: 1.8;
          a{
            color: white;
          }
          a:hover{
            color: #2da0db;
          }
        }
        & > li:nth-child(1){
          margin-bottom: 20px;
          font-size: 20px;
        }
      }
      .menu-item + .menu-item{
        margin-left: 90px;
      }
      .footer-menu-right{
        padding-top: 20px;
        text-align: right;
        .ewm-item{
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          img{
            width: 120px;
          }
          div{
            text-align: center;
            color: white;
            width: 120px;
            font-size: 14px;
            line-height: 40px;
          }
        }
        .logo{
          width: 150px;
          margin-top: 16px;
        }
      }
    }
    .footer-bottom{
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      & > img:nth-child(1){
        width: 20px;
        height: 27px;
      }
      & > span:nth-child(2){
        color: white;
        font-size: 13px;
        margin-left: 10px;
      }
      & > a:nth-child(3){
        color: #535353;
        font-size: 13px;
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .footer-menu{
    .menu-item{
      float: none!important;
      width: 100%!important;
      display: flex;
      flex-direction: column;
      margin-left: 0!important
    }
    .footer-menu-right{
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  .footer-bottom{
    flex-direction: column;
  }
}
</style>